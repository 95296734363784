<template>
  <div class="chinese-wrap" v-loading="loading">
    <div class="chinese-wrap-title">
      订阅权益
    </div>
    <div class="chinese-wrap-content">
      <div class="chinese-wrap-content-user" v-if="memberInfo.id">
        <div class="name">
          <div class="name-text" v-if="memberInfo.mobile">{{ memberInfo.mobile }}</div>
          <div class="name-text" v-else-if="memberInfo.email">{{ memberInfo.email }}</div>
          <div class="member-tag vip-tag" v-if="memberInfo.vip_level">{{ memberInfo.user_level_name }}</div>
          <div class="member-tag ordinary-tag" v-else>普通会员</div>
        </div>
        <div class="tip" v-if="memberInfo.vip_level">
          <span>会员有效期至：{{ $moment(memberInfo.vip_expire).format('YYYY-MM-DD') }}</span>
        </div>
        <div class="tip" v-else>
          <span>暂未开通VIP会员</span>
        </div>
      </div>
      <div class="chinese-wrap-content-list">
        <div class="chinese-wrap-content-list-cell" v-for="(item, index) in tempTabList" :key="index">
          <div class="chinese-wrap-content-list-cell-header">
            {{ item.name }}{{ item.obj && item.obj.user_level_name ? item.obj.user_level_name : "" }}订阅价格{{ item.price }}元
          </div>
          <div class="chinese-wrap-content-list-cell-content">
            <div class="chinese-wrap-content-list-cell-content-tip">
              {{ item.obj && item.obj.rights ? item.obj.rights : "" }}
            </div>
            <div class="chinese-wrap-content-list-cell-content-btn" @click="subscribe(item.obj, item.id)">订阅</div>
          </div>
        </div>
      </div>
      <div class="chinese-wrap-content-tip">
        {{ agency.prompt }}
        <a :href="'tel:'+companyPhone" class="phone" v-if="companyPhone">{{companyPhone}}</a>
      </div>
    </div>
    <!-- 选择项目进行支付 -->
    <chinese-project ref="chineseProjectRef"></chinese-project>
  </div>
</template>

<script>
import { memberLevelList, initConfig, userInfo } from "@/api";
import ChineseProject from "../component/chineseProject";
export default {
  name: "ChineseWrap",
  components: {
    ChineseProject
  },
  data() {
    return {
      loading: false,
      // 默认权益列表
      tabList: [],
      // 模板循环权益列表
      tempTabList: [],
      // 机构对象
      agency: {},
      // 联系电话
      companyPhone: "",
      // 会员服务协议
      serviceAgreement: "",
      // 用户信息
      memberInfo: {}
    }
  },
  created() {
    this.getUserInfo();
    this.getInitConfig();
    this.getMemberLevelList();
  },
  methods: {
    // 获取会员类型列表
    async getMemberLevelList() {
      this.loading = true;
      let res = await memberLevelList({ search: "status:1", searchFields: "status:=", orderBy: "id", sortedBy: "asc" });
      if (res && res.code == 200) {
        this.tabList = res.data.list;
        this.levelHandle(res.data.list);
      }
      this.loading = false;
    },
    // 权益数据处理
    levelHandle(rows) {
      if (rows && rows.length > 0) {
        let arr = [];
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (item.user_level_details && item.user_level_details.length > 0) {
            for (let j = 0; j < item.user_level_details.length; j++) {
              let jtem = item.user_level_details[j];
              jtem['obj'] = item;
              arr.push(jtem);
            }
          } else {
            this.agency = item;
          }
        }
        this.tempTabList = arr;
      }
    },
    // 获取配置信息
    async getInitConfig() {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "CONNECT_MOBILE") {
              this.companyPhone = item.config_value;
            } else if (item.config_key == "MEMBERSHIP_SERVICE_AGREEMENT") {
              this.serviceAgreement = item.config_value;
            }
          }
        }
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await userInfo();
        if (res && res.code == 200) {
          this.memberInfo = res.data.user_info;
        }
      }
    },
    // 开始订阅
    subscribe(row, levelId) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.$refs.chineseProjectRef.open(row, this.serviceAgreement, levelId);
      } else {
        this.$emit("login");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chinese-wrap {
  max-width: 1200px;
  margin: 0 auto;
  font-family: HarmonyOS_Regular;
  &-title {
    font-size: 30px;
    text-align: center;
    font-family: HarmonyOS_Bold;
    margin-top: 80px;
  }
  &-content {
    margin-top: 80px;
    min-height: 600px;
    &-user {
      display: flex;
      margin-left: 12%;
      margin-bottom: 20px;
      .name {
        display: flex;
        margin-right: 15px;
      }
      .member-tag {
        font-size: 12px;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 5px;
        margin-left: 10px;
      }
      .vip-tag {
        color: #ffffff;
        background-color: #ed6f33;
      }
      .ordinary-tag {
        color: #ffffff;
        background-color: #d9d9d9;
      }
    }
    &-list {
      margin: 0 auto;
      display: flex;
      &-cell {
        width: 30%;
        margin-top: 30px;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        border: 1px solid #999999;
        &:last-child {
          margin-right: 0;
        }
        &-header {
          font-family: HarmonyOS_Bold;
          font-size: 16px;
          padding: 15px 30px;
          text-align: center;
          background-color: #d9d9d9;
        }
        &-content {
          flex: 1;
          padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &-tip {
            flex: 1;
            text-align: center;
            line-height: 1.5;
            font-size: 14px;
            padding: 30px 0;
          }
          &-btn {
            cursor: pointer;
            user-select: none;
            color: #ffffff;
            text-align: center;
            padding: 10px 30px;
            font-size: 16px;
            font-family: HarmonyOS_Bold;
            background-color: #ed6f33;
            display: inline-block;
            &:hover, &:active {
              background-color: #f28551;
            }
          }
        }
      }
    }
    &-tip {
      font-size: 18px;
      margin-top: 80px;
      .phone {
        color: #ed6f33;
        font-family: HarmonyOS_Bold;
        text-decoration: none;
        display: inline-block;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .chinese-wrap {
    &-title {
      margin-top: 50px;
      font-size: 26px;
    }
    &-content {
      margin-top: 50px;
      &-list {
        padding: 0 30px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .chinese-wrap {
    &-title {
      margin-top: 50px;
      font-size: 26px;
    }
    &-content {
      margin-top: 50px;
      &-user {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-bottom: 20px;
        .name {
          flex-direction: column;
          align-items: center;
        }
        .member-tag {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 5px;
          margin-left: 10px;
        }
        .tip {
          margin-top: 10px;
        }
        .vip-tag {
          color: #ffffff;
          background-color: #ed6f33;
          display: inline-block;
        }
        .ordinary-tag {
          color: #ffffff;
          background-color: #d9d9d9;
          display: inline-block;
        }
      }
      &-list {
        width: calc(100% - 60px);
        padding: 0 30px;
        flex-direction: column;
        &-cell {
          width: 100%;
          margin-right: 0;
        }
      }
      &-tip {
        line-height: 1.6;
        margin-top: 0;
        padding: 30px;
      }
    }
  }
}
</style>